import * as React from "react"
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/Solutions"

import Layout from "../components/layout"
import SEO from "../components/seo"


const SolutionsUseCases = (props) => {
  const { data } = props
  if (!data) return null  
  const pageData = data.prismicSolutionsUsecases
  const page = pageData.data || {}
  return(
    <Layout theme={props.pageContext.header_theme && props.pageContext.header_theme}>   
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <div className="background1">
        <SliceZone
          slices={page.body}
          components={components}
          context={pageData}
          defaultComponent={() => null}
        />  
      </div>
    </Layout>
  );
};

export default SolutionsUseCases


export const query = graphql`
  query solutionsQuery($uid: String!){
    prismicSolutionsUsecases(uid: {eq: $uid}) {
        uid
        id
        type
        data{
          body{
                ... on PrismicSliceType {
                  slice_type
                }
                ...solutionsHeroSlice
                ...solutionsContentSlice
                ...casestudiesHeroSlice
            }        
        }
    }
  }
`
