import React, { useEffect, useRef, useState } from 'react'
import { startCase } from 'lodash';
import { graphql } from 'gatsby';
import axios from 'redaxios';
import bird from '../../assets/images/bird-1.webp';
import { blockEvent, form } from '../../frontend-shared/helpers';
import { FormButton } from '../../frontend-shared/components';
import { sendGtagConversionEvent } from '../../helpers/gtag';
import CustomerExperience from '../Elements/CustomerExperience';
import CaseStudies from '../Elements/CaseStudies';

import "./Solutions.css";

const ActionText = {
  download: 'Download',
  submitted: 'The case study should be in your inbox soon!',
  tryAgain: 'We could not submit your request, try again',
};

const subjectMap = {
  'customer-experience': 'customer-engagement',
};

const CaseStudySection = ({
  slice: {
    primary: {
      heading,
      testimonial: rawTestimonial,
      case_studies_title: caseStudiesTitle,
    },
    items,
  },
}) => {
  const testimonial = rawTestimonial?.document?.data;

  const subjectKey = typeof window === 'undefined'
    ? ''
    : window.location.pathname.replace(/\/+$/, '').split('/').at(-1);
  const subject = startCase(subjectMap[subjectKey] || subjectKey);

  const formRef = useRef(null);

  const [actionState, setActionState] = useState('ready');
  const [actionText, setActionText] = useState(ActionText.download);
  const [error, setError] = useState({});
  const [state, setState] = useState({ email: '' });

  useEffect(() => { setError({}); }, [state]);
  useEffect(() => { setActionText(ActionText.download); }, [state]);

  const updateState = (update) => setState({ ...state, ...update });

  const { email } = state;

  const preSubmit = (event) => {
    blockEvent(event);
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError({ ...error, email: 'Entered value does not match email format' });
      return;
    }
    form.submit(formRef);
  };

  const onSubmit = actionText === ActionText.tryAgain ? () => {
    setActionState('waiting');
    setActionText(ActionText.download);
    setTimeout(() => { setActionState('ready'); }, 250);
  } : (event) => {
    blockEvent(event);
    const form = formRef.current;
    const url = form.getAttribute('action');
    const method = form.getAttribute('method');
    setActionState('waiting');
    sendGtagConversionEvent('AW-604624620/vfEJCLSs6N8ZEOytp6AC');
    return axios[method](url, new FormData(form)).then(() => {
      setActionText(ActionText.submitted);
      localStorage.setItem('email', email);
      setTimeout(() => setActionState('disabled'), 500);
    }).catch(() => {
      setTimeout(() => setActionState('ready'), 500);
      setActionText(ActionText.tryAgain);
    });
  };

  const submitted = actionText !== ActionText.download;

  return (
    <>
      <div className="max-w-7xl mx-auto text-center px-4 relative z-10">
        <div className="bg-white overflow-hidden drop-shadow-md rounded-3xl lg:py-40 md:py-32 py-20 sm:px-10 px-4 text-blue">
          <h2 className="lg:text-7xl md:text-6xl text-4xl tracking-tighter font-medium">{heading && heading.text}</h2>
            <form
              action="https://crm.zoho.com/crm/WebToLeadForm"
              acceptCharset="utf-8"
              className="flex flex-col gap-4 mt-12"
              onSubmit={onSubmit}
              method="post"
              name="WebToLeads5159490000000767164"
              ref={formRef}
            >
              <div className="hidden">
                <input aria-hidden="true" type="text" name="xnQsjsdp" value="5415f13a37fdfe95794bf3f265bfae469ae6c2d0068174bb5e27f5db5219a775" />
                <input aria-hidden="true" type="hidden" name="zc_gad" value="" />
                <input aria-hidden="true" type="text" name="xmIwtLD" value="99b909cfe362a59d7be202acdef7be6adc99ea230d203d668c7c5f3ec87242a4" />
                <input aria-hidden="true" type="text" name="actionType" value="TGVhZHM=" />
                <input aria-hidden="true" type="text" name="returnURL" value="https&#x3a;&#x2f;&#x2f;deploy-preview-6--carriyo-gatsby-demo.netlify.app&#x2f;contact&#x2f;"  />
              </div>
              <div className="flex md:flex-row flex-col gap-4 md:gap-0 items-stretch max-w-xl mx-auto md:rounded-full md:bg-white md:shadow-2xl w-full">
                <div className="hidden">
                  <select name="Lead Source">
                    <option selected value={`Website - Case Study Download: ${subject}`}>
                      Case Study
                    </option>
                  </select>
                </div>
                <input
                  className="bg-white md:shadow-none shadow-2xl rounded-full text-lg leading-none text-gray-800 py-3 px-5 md:w-4/5 focus:outline-none transition-width ease-in-out"
                  style={!submitted ? {} : {
                    visibility: 'hidden',
                    width: '0px',
                    padding: '0px',
                  }}
                  name="Email"
                  onChange={({ target }) => updateState({ email: target.value })}
                  value={email}
                  required
                  placeholder="Enter your email"
                  type="email"
                />
                <button className="hidden" hidden type="submit">
                  The real submit button
                </button>
                <FormButton size="lg" width={submitted ? 'full' : undefined } state={actionState} onClick={preSubmit}>
                  {actionText}
                </FormButton>
              </div>
              {error.email && <span role="alert">Please enter a valid email Address</span>}
            </form>
        </div>
      </div>
      {testimonial ? (
        <div className="bg-gradient pt-40 pb-32 relative text-white -top-40 z-0">
          <img
            className="absolute left-0 top-0 scale-y-[30%] -scale-x-[30%] rotate-[-18deg] -translate-y-1/2 -translate-x-1/2 z-20"
            src={bird}
          />
          <div className="max-w-7xl mx-auto px-8 py-12 relative z-10">
            <span className="Solutions-stripe Solutions-stripe--head" />
            <span className="Solutions-stripe Solutions-stripe--tail" />
            <div className="relative z-10">
              <div className="flex items-center mb-6 author-img">
                <img
                  loading="lazy"
                  src={testimonial?.image.fluid.srcWebp}
                  srcSet={testimonial?.image.fluid.srcSetWebp}
                  alt={testimonial?.image.alt}
                  width={88}
                  height={88}
                  className="mb-0"
                />
                <div className="pl-5">
                  <div className="font-inter text-lg font-bold">{testimonial?.author}</div>
                  <p className="md:text-base text-sm mb-0">{testimonial?.designation}</p>
                </div>
              </div>
              <div className="sm:text-lg md:text-base text-sm tm-text italic mb-0">
                “{testimonial?.content}”
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-24" />
      )}
      <div className="w-full mb-12">
        <CaseStudies
          title={caseStudiesTitle}
          studies={(items || []).map(({
            casestudy_item: {
              document: {
                data: {
                  title,
                  description,
                  image,
                  mobile_image,
                  client_logo,
                  casestudy_link: href,
                } = {},
              } = {},
            } = {},
          }) => ({
            client_logo,
            description,
            href,
            image,
            mobile_image,
            title,
          }))}
        />
      </div>

      <div className="xl:py-40 md:pt-24 pt-16 pb-24 text-blue">
        <CustomerExperience items="clients" theme="light" />
      </div>
    </>
  )
};

export default CaseStudySection;

export const query = graphql`
  fragment casestudiesHeroSlice on PrismicSolutionsUsecasesDataBodyCasestudies {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
      testimonial {
        document {
          ... on PrismicTestimonials {
            id
            data {
              author
              designation
              content
              image {
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
      case_studies_title
    }
    items {
      casestudy_item {
        document {
          ... on PrismicCasestudies {
            id
            data {
              casestudy_link
              title {
                text
                html
              }
              description {
                text
                html
              }
              image {
                dimensions {
                  width
                  height
                }
                fluid(maxHeight: 376, maxWidth: 530) {
                  srcWebp
                  srcSetWebp
                }
              }
              mobile_image {
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 230, maxHeight: 260) {
                  srcWebp
                  srcSetWebp
                }
              }
              client_logo {
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 112, maxHeight: 48) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
