import * as React from "react"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const ContentBlks = ({ slice }) => {
    const { items } = slice
    return (
        <div className="max-w-7xl mx-auto px-4">
            {
            items && items.map((item, itemindex)=>{
                const { heading, content, image, background, bottom_bg_image } = item
                return(
                    <div key={itemindex}
                        className={
                            "shadow-2xl rounded-3xl md:p-10 py-6 px-4 mb-12 " +
                            (background === 'light' ? "text-blue bg-white"  : "text-white bg-gradient")
                        }
                    >
                        <div className="flex justify-between">
                            <h2 className="sm:text-5xl text-3xl font-medium md:mb-8 mb-4 tracking-tighter">{heading && heading.text}</h2>
                            <h3 className="sm:text-4xl text-xl tracking-tight font-medium">0{itemindex+1}</h3>
                        </div>
                        <div className="md:grid md:grid-cols-2 grid-cols-1 gap-6 flex flex-col-reverse">
                            <div>
                                <GatsbyImage image={getImage(image)} alt={heading && heading.text} className="shadow-xl rounded-2xl"/>
                            </div>
                            <div className="relative p-left">
                            <PrismicRichText
                                field={content.richText}
                                className="richtext-content"
                                components={{
                                   listItem: ({ children }) => <li><div className="flex gap-2 md:mt-6 xl:pr-20 lg:pr-10">
                                    <div className="w-12 h-12 mt-2 md:block hidden">
                                        <StaticImage src="../../assets/images/spheres.png" alt={heading && heading.text} width={15} height={15} /> </div>
                                    <p className="md:text-xl text-lg md:leading-8 mb-4 z-10">{children}</p>
                                </div></li>
                                }}
                            />
                                {/* Use highest quality image by removing compression queries */}
                                <img
                                  loading="lazy"
                                  src={bottom_bg_image.fluid.srcWebp}
                                  srcSet={bottom_bg_image.fluid.srcSetWebp}
                                  width={Math.round(bottom_bg_image.dimensions.width/2)}
                                  height={Math.round(bottom_bg_image.dimensions.height/2)}
                                  className="md:block hidden absolute -bottom-16 -right-10 rounded-3xl"
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
            
        </div>
    )
}

export default ContentBlks


export const query = graphql`
  fragment solutionsContentSlice on PrismicSolutionsUsecasesDataBodySolutionsContent {
    id
    slice_type
    items {
    background
    heading {
        text
        html
    }
    content {
        text
        richText
    }
    image {
        gatsbyImageData(layout: FULL_WIDTH)
    }
    bottom_bg_image {
        dimensions {
        width
        height
        }
        fluid(maxWidth: 600, maxHeight: 600) {
        srcWebp
        srcSetWebp
        }
    }
    }
  }
`
