import React from "react"
import { graphql } from "gatsby";

const HeroSection = ({ slice }) => {
    const { primary } = slice
    const { heading, sub_heading, hero_mobile_image, hero_image, client_logo } = primary
    return (
        <div className="max-w-7xl mx-auto px-4 md:text-center text-left xl:pt-52 md:pb-20 pb-16 pt-40">
            <div className="md:hidden block float-right -mr-4 -mt-8">
                <img
                    loading="lazy"
                    alt={hero_mobile_image.alt}
                    src={hero_mobile_image.fluid.srcWebp}
                    srcSet={hero_mobile_image.fluid.srcSetWebp}
                    width={Math.round(hero_mobile_image.dimensions.width/2)}
                    height={Math.round(hero_mobile_image.dimensions.height/2)}
                />
            </div>
            <img
                    loading="lazy"
                    alt={client_logo.alt}
                    src={client_logo.fluid.srcWebp}
                    srcSet={client_logo.fluid.srcSetWebp}
                    width={Math.round(client_logo.dimensions.width/2)}
                    height={Math.round(client_logo.dimensions.height/2)}
                    className="md:mb-12 mb-6 md:mx-auto mx-0" 
                />
            <h1 className="xl:text-7xl lg:text-6xl sm:text-5xl text-3xl text-blue font-medium tracking-tighter mb-10">{heading && heading.text}</h1>
            <p className="text-blue text-xl md:mx-auto md:mb-16 mb-0">{sub_heading && sub_heading}</p>
            <div className="md:block hidden" >
                <img
                    loading="lazy"
                    alt={hero_image.alt}
                    src={hero_image.fluid.srcWebp}
                    srcSet={hero_image.fluid.srcSetWebp}
                    width={Math.round(hero_image.dimensions.width/2)}
                    height={Math.round(hero_image.dimensions.height/2)}
                    className="mx-auto"
                />
            </div>
        </div>
    )
}
export default HeroSection

export const query = graphql`
  fragment solutionsHeroSlice on PrismicSolutionsUsecasesDataBodyHero {
    id
    slice_type
    primary {
      heading {
        html
        text
      }
      sub_heading
      client_logo {
        alt
        fluid(maxWidth: 361, maxHeight: 129) {
          srcWebp
          srcSetWebp
        }
        dimensions {
          width
          height
        }
      }
      hero_image {
        alt
        fluid(maxWidth: 520, maxHeight: 520) {
          srcWebp
          srcSetWebp
        }
        dimensions {
          width
          height
        }
      }
      hero_mobile_image {
        alt
        fluid(maxWidth: 188, maxHeight: 272) {
          srcSetWebp
          srcWebp
        }
        dimensions {
          width
          height
        }
      }
    }
  }
`
